import React from "react"
import Layout from "gatsby-theme-yum-components/src/components/modules/Layout"
import BodyBlocks from "gatsby-theme-yum-components/src/components/technical/BodyBlocks"
import { Helmet } from "react-helmet"

const Location1Page = () => {
  const body = [
    {
      component: "masonry_grid_block",
      cards: [
        {
          image: { filename: "https://picsum.photos/600/600" },
          category: "Burgfestspiele",
          type: "Theater",
          headline: "Theaterstück mit langem Titel 1",
          date: "7.7.-8.8.",
        },
        {
          image: { filename: "https://picsum.photos/600/400" },
          category: "Burgfestspiele",
          type: "Theater",
          headline: "Theaterstück 2",
          date: "7.7.-8.8.",
        },
        {
          image: { filename: "https://picsum.photos/600/900" },
          category: "Burgfestspiele",
          type: "Theater",
          headline: "Theaterstück mit Titel 3",
          date: "7.7.-8.8.",
        },
        {
          image: { filename: "https://picsum.photos/600/400" },
          category: "Burgfestspiele",
          type: "Theater",
          headline: "Theaterstück mit Titel 4",
          date: "7.7.-8.8.",
        },
        {
          image: { filename: "https://picsum.photos/600/900" },
          category: "Burgfestspiele",
          type: "Theater",
          headline: "Theaterstück mit langem Titel 5",
          date: "7.7.-8.8.",
        },
        {
          image: { filename: "https://picsum.photos/600/600" },
          category: "Burgfestspiele",
          type: "Theater",
          headline: "Theaterstück mit Titel 6",
          date: "7.7.-8.8.",
        },
        {
          image: { filename: "https://picsum.photos/600/900" },
          category: "Burgfestspiele",
          type: "Theater",
          headline: "Theaterstück 7",
          date: "7.7.-8.8.",
        },
        {
          image: { filename: "https://picsum.photos/600/600" },
          category: "Burgfestspiele",
          type: "Theater",
          headline: "Theaterstück mit sehr, sehr, sehr langem Titel 8",
          date: "7.7.-8.8.",
        },
        {
          image: { filename: "https://picsum.photos/600/400" },
          category: "Burgfestspiele",
          type: "Theater",
          headline: "Theaterstück mit Titel 9",
          date: "7.7.-8.8.",
        },
      ],
    },
    {
      component: "text_image_block",
      image: { filename: "https://picsum.photos/960/540" },
      headline: "Aktuell Sommer 2020",
      text:
        "Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine. Ich bin so glücklich, mein Bester, so ganz in dem Gefühle von ruhigem Dasein versunken, daß meine Kunst darunter leidet. Ich könnte jetzt nicht zeichnen, nicht einen Strich, und bin nie ein größerer Maler gewesen als in diesen Augenblicken. ",
    },
    {
      component: "text_image_block",
      text_position: "right",
      image: { filename: "https://picsum.photos/960/540" },
      headline: "Aktuell Sommer 2020",
      text:
        "Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine. Ich bin so glücklich, mein Bester, so ganz in dem Gefühle von ruhigem Dasein versunken, daß meine Kunst darunter leidet. Ich könnte jetzt nicht zeichnen, nicht einen Strich, und bin nie ein größerer Maler gewesen als in diesen Augenblicken. ",
    },
    {
      component: "image_block",
      block_width: "content",
      image: { filename: "https://picsum.photos/1920/1080" },
    },
    {
      component: "image_block",
      image: { filename: "https://picsum.photos/1920/1080" },
    },
    {
      component: "image_block",
      block_width: "screen",
      image: { filename: "https://picsum.photos/1920/1080" },
    },
    {
      component: "text_block",
      headline: "Aktuell Sommer 2020",
      text:
        "Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine. Ich bin so glücklich, mein Bester, so ganz in dem Gefühle von ruhigem Dasein versunken, daß meine Kunst darunter leidet. Ich könnte jetzt nicht zeichnen, nicht einen Strich, und bin nie ein größerer Maler gewesen als in diesen Augenblicken. ",
    },
  ]
  return (
    <Layout>
      <Helmet
        bodyAttributes={{
          class: "theme-burgfestspiele font-body antialiased",
        }}
      />
      <BodyBlocks body={body} />
    </Layout>
  )
}

export default Location1Page
